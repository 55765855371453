<template>
  <div class="logCheck">
    <el-tabs type="card" style="margin-top:30px;">
      <el-tab-pane label="登录日志">
        <div class="signInLog" style="padding:20px;">
          <div>
            <el-form :inline="true" :model="formLogin" class="demo-form-inline">
              <el-form-item label="账号" style="width: 10.8rem;">
                <el-input style="width: 8rem;" v-model="formLogin.phone" placeholder="账号" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item label="人员" style="width: 10.8rem;">
                <el-input style="width: 8rem;" v-model="formLogin.name" placeholder="人员" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item label="角色" style="width: 13rem;">
                <el-select
                  style="width: 9rem;"
                  size="small"
                  placeholder="全部"
                  multiple
                  clearable
                  v-model="formLogin.roleIds"
                >
                  <el-option
                    v-for="(item, index) in roles"
                    :key="index"
                    :label="item.name"
                    :value="item.roleId"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="登录时间">
                <el-date-picker
                  size="small"
                  type="daterange"
                  v-model="createTimeLogin"
                  format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" @click="getLoginList(1)">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-table
              :data="loginData.data"
              v-loading="$store.state.requestLoading"
              style="width: 100%">
              <el-table-column
                label="登录时间"
                width="180">
                <template slot-scope="scope">
                  {{ $util.dateFormat(scope.row.loginLogoutTime) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="account"
                label="账号"
                width="180">
              </el-table-column>
              <el-table-column
                prop="operatorName"
                label="人员"
                width="180">
              </el-table-column>
                <el-table-column
                label="角色">
                <template slot-scope="scope">
                  <span v-if="scope.row.role && scope.row.role.length>0">
                    <span v-for="(item,index) in scope.row.role" :key="index">
                      {{(index!==scope.row.role.length-1)?
                      item.roleName +  ','
                      :item.roleName + ''}}</span>
                  </span>
                  <span v-else></span>
                </template>
                </el-table-column>
                <el-table-column
                prop="ip"
                label="IP地址">
                </el-table-column>
                <el-table-column
                prop="browser"
                label="登录设备">
              </el-table-column>
            </el-table>
            <div class="paginationArea pagination pager">
              <el-pagination
                background
                layout="total, prev, pager, next"
                :total="loginData.total"
                :current-page="loginData.pageNo"
                :page-size="loginData.pageSize"
                @size-change="handleSizeChange02"
                @current-change="handleCurrentChangeLogin"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="操作日志">
        <div class="oprationLog" style="padding:20px;">
          <div>
            <el-form :inline="true" :model="formLog" class="demo-form-inline">
              <el-form-item label="操作人员" style="width: 13rem;">
                <el-input style="width: 8rem;" v-model="formLog.name" placeholder="操作人员" size="small" clearable></el-input>
              </el-form-item>
              <el-form-item label="操作模块" style="width: 13rem;">
                <el-select style="width: 8rem;" v-model="formLog.module" placeholder="操作模块" size="small" clearable>
                  <el-option
                        v-for="(item, index) in menuList"
                        :key="index"
                        :value="item"
                        :label="item"
                      >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作类型" style="width: 13rem;">
                <el-select style="width: 8rem;" v-model="formLog.action" placeholder="操作类型" size="small" clearable>
                  <el-option label="新增" value="insert"></el-option>
                  <el-option label="修改" value="update"></el-option>
                  <el-option label="删除" value="delete"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="操作时间">
                <el-date-picker
                  size="small"
                  type="daterange"
                  v-model="createTimeLog"
                  format="yyyy-MM-dd"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" @click="getLogList(1)">查询</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-table
              :data="logData.data"
              v-loading="$store.state.requestLoading"
              style="width: 100%">
              <el-table-column
                label="操作时间"
                width="180">
                <template slot-scope="scope">
                  {{ $util.dateFormat(scope.row.createTime) }}
                </template>
              </el-table-column>
              <el-table-column
                prop="operatorName"
                label="操作人员"
                width="180">
              </el-table-column>
              <el-table-column
                prop="ip"
                label="IP地址">
                </el-table-column>
                <el-table-column
                prop="module"
                label="操作模块">
                </el-table-column>
                <el-table-column
                prop="actionName"
                label="操作类型">
                </el-table-column>
                <el-table-column
                prop="summary"
                label="操作详情">
              </el-table-column>
            </el-table>
            <div class="paginationArea pagination pager">
              <el-pagination
                background
                layout="total, prev, pager, next"
                :total="logData.total"
                :current-page="logData.pageNo"
                :page-size="logData.pageSize"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChangeLog"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>

export default {
  data () {
    return {
      createTimeLogin: [],
      formLogin: {
        name: '',
        phone: '',
        roleIds: [],
        startDate: '',
        endDate: ''
      },
      createTimeLog: '',
      formLog: {
        action: '',
        module: '',
        name: '',
        startDate: '',
        endDate: ''
      },
      roles: [],
      logData: {
        data: [],
        total: 0,
        pageNo: 1,
        PageSize: 10
      },
      loginData: {
        data: [],
        total: 0,
        pageNo: 1,
        PageSize: 10
      },
      menuList: []
    }
  },
  created () {
    this.getLoginList(1)
    this.getLogList(1)
    this.getAccountRoleList()
    this.menusList()
  },
  methods: {

    // 登录日志
    async getLoginList (pageNo) {
      const res = await this.$api.loginList(
        Object.assign(this.formLogin, {
          pageNo: pageNo || this.loginData.pageNo,
          pageSize: this.loginData.pageSize
        })
      )
      if (res !== 'fail&&error') {
        this.loginData.data = res.data
        this.loginData.total = res.total
      }
    },
    // 操作日志
    async getLogList (pageNo) {
      const res = await this.$api.logList(
        Object.assign(this.formLog, {
          pageNo: pageNo || this.logData.pageNo,
          pageSize: this.logData.pageSize
        })
      )
      if (res !== 'fail&&error') {
        this.logData.data = res.data
        this.logData.total = res.total
      }
    },
    // 全部角色列表查询
    async getAccountRoleList () {
      const res = await this.$api.getAccountRoleList({})
      if (res !== 'fail&&error') {
        this.roles = res
      }
    },
    async menusList () {
      const res = await this.$api.menusList(this.searchForm)
      if (res !== 'fail&&error') {
        res.map(item => {
          item.children && item.children.map(ele => {
            this.menuList.push(ele.name)
          })
        })
      }
    },
    handleSizeChange () {

    },
    handleCurrentChangeLog (pageNo) {
      this.logData.pageNo = pageNo
      this.getLogList(pageNo)
    },
    handleSizeChange02 () {

    },
    handleCurrentChangeLogin (pageNo) {
      this.loginData.pageNo = pageNo
      this.getLoginList(pageNo)
    }
  },
  watch: {
    createTimeLogin (arrVal) {
      if (arrVal) {
        this.formLogin.startDate = new Date(arrVal[0]).getTime()
        this.formLogin.endDate = new Date(arrVal[1]).getTime()
      } else {
        this.formLogin.startDate = this.formLogin.endDate = null
      }
    },
    createTimeLog (arrVal) {
      if (arrVal) {
        this.formLog.startDate = new Date(arrVal[0]).getTime()
        this.formLog.endDate = new Date(arrVal[1]).getTime()
      } else {
        this.formLog.startDate = this.formLog.endDate = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logCheck {
  .search-box {
    border: .5px solid #ebeef5;
    padding: 0.66rem 1.2rem 0.12rem;
    .el-form-item {
      margin-bottom: 0.66rem;
    }
  }
  .pager {
    text-align: right;
    padding: 0.66rem 0 0;
  }
}
</style>
